<template>
  <b-container fluid>
    <b-card class="card-border text--black mb-4">
      <b-row>
        <b-col cols="12" v-if="ticketoverview">
          <h3>Ticket Status Overview: {{ ticketFilters.month }} {{ ticketFilters.year }}</h3>
          <BarChart :data="ticketChart" :options="chartOptions"></BarChart>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" offset-md="6">
          <b-button block @click="$router.push('/report/ticket')">View Full Report</b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card class="card-border text--black">
      <b-row>
        <b-col cols="12">
          <h3>Companies</h3>
        </b-col>
        <b-col cols="12" v-if="companyoverview" style="overflow: auto;">
          <b-table :items="companyoverview" :fields="columns" class="w-100">
            <template #cell(name)="data">
              <router-link :to="'/admin/company/' + data.item.id" class="text--black u">
                {{ data.item.name }}
              </router-link>
            </template>
            <template #cell(organization)="data">
              {{ data.item.organization }}
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="5">
          <b-button variant="primary" block @click="$router.push('/report/quickbooks')">Quickbooks Report</b-button>
        </b-col>
        <b-col cols="5" offset="2">
          <b-button block @click="$router.push('/report/company')">View Full Report</b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>
<script>
import BarChart from '@/components/dashboard/reports/charts/Bar'
import TicketService from '@/services/TicketService'
import moment from 'moment'
import CompanyService from '@/services/CompanyService'

export default {
  data () {
    return {
      ticketoverview: null,
      ticketFilters: {
        year: '',
        month: ''
      },
      companyoverview: null,
      columns: [
        'name',
        'organization',
        { key: 'companytype', label: 'Company Type' },
        'bookkeeper',
        { key: 'lastsync', label: 'Last QB Sync' },
        'updated'
      ]
    }
  },

  components: {
    BarChart
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      this.getTicketOverview()
      this.getCompanyOverview()
    },

    getTicketOverview () {
      const year = moment().format('YYYY')
      const month = moment().format('MM')

      this.ticketFilters.year = year
      this.ticketFilters.month = moment().format('MMM')

      const filter = {
        year: year,
        month: month,
        open: 1
      }
      TicketService.breakdown(filter).then(
        (res) => {
          this.ticketoverview = res.data.info.stats
        }
      )
    },

    getCompanyOverview () {
      const filter = {
        max: 5,
        page: 1,
        order: [{
          column: 'c.updated',
          direction: 'desc'
        }],
        active: 1
      }

      CompanyService.search({ filters: filter }).then(
        (response) => {
          this.companyoverview = response.data.info.results
        }
      )
    }
  },

  computed: {
    ticketChart () {
      const detotal = this.ticketoverview[0].open + this.ticketoverview[0].hold + this.ticketoverview[0].late + this.ticketoverview[0].waiting + this.ticketoverview[0].review + this.ticketoverview[0].quaterly + this.ticketoverview[0].semi + this.ticketoverview[0].annual + this.ticketoverview[2].cwq + this.ticketoverview[0].complete
      const ptotal = this.ticketoverview[1].open + this.ticketoverview[1].hold + this.ticketoverview[1].late + this.ticketoverview[1].waiting + this.ticketoverview[1].review + this.ticketoverview[1].quaterly + this.ticketoverview[1].semi + this.ticketoverview[1].annual + this.ticketoverview[2].cwq + this.ticketoverview[1].complete
      const ftotal = this.ticketoverview[2].open + this.ticketoverview[2].hold + this.ticketoverview[2].late + this.ticketoverview[2].waiting + this.ticketoverview[2].review + this.ticketoverview[2].quaterly + this.ticketoverview[2].semi + this.ticketoverview[2].annual + this.ticketoverview[2].cwq + this.ticketoverview[2].complete
      const chartData = {
        labels: ['Data Entry (' + detotal + ')', 'Preliminary (' + ptotal + ')', 'Finalized (' + ftotal + ')'],
        datasets: [
          {
            label: 'Open',
            data: [this.ticketoverview[0].open, this.ticketoverview[1].open, this.ticketoverview[2].open],
            backgroundColor: '#BE191E'
          },
          {
            label: 'Hold',
            data: [this.ticketoverview[0].hold, this.ticketoverview[1].hold, this.ticketoverview[2].hold],
            backgroundColor: '#FF6C00'
          },
          {
            label: 'Late',
            data: [this.ticketoverview[0].late, this.ticketoverview[1].late, this.ticketoverview[2].late],
            backgroundColor: '#FFBA00'
          },
          {
            label: 'Waiting',
            data: [this.ticketoverview[0].waiting, this.ticketoverview[1].waiting, this.ticketoverview[2].waiting],
            backgroundColor: '#985E00'
          },
          {
            label: 'Review',
            data: [this.ticketoverview[0].review, this.ticketoverview[1].review, this.ticketoverview[2].review],
            backgroundColor: '#15A2B8'
          },
          {
            label: 'Quarterly',
            data: [this.ticketoverview[0].quaterly, this.ticketoverview[1].quaterly, this.ticketoverview[2].quaterly],
            backgroundColor: '#0D47A1'
          },
          {
            label: 'Semi-Annual',
            data: [this.ticketoverview[0].semi, this.ticketoverview[1].semi, this.ticketoverview[2].semi],
            backgroundColor: '#BE19A5'
          },
          {
            label: 'Annual',
            data: [this.ticketoverview[0].annual, this.ticketoverview[1].annual, this.ticketoverview[2].annual],
            backgroundColor: '#B0BEC5'
          },
          {
            label: 'Closed w/ Questions',
            data: [this.ticketoverview[0].cwq, this.ticketoverview[1].cwq, this.ticketoverview[2].cwq],
            backgroundColor: '#80E27E'
          },
          {
            label: 'Complete',
            data: [this.ticketoverview[0].complete, this.ticketoverview[1].complete, this.ticketoverview[2].complete],
            backgroundColor: '#28A745'
          }
        ]
      }
      return chartData
    },

    chartOptions () {
      return {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: 1,
              min: 0
            },
            gridLines: {
              display: true
            },
            stacked: true
          }],
          xAxes: [{
            gridLines: {
              display: true
            },
            stacked: true
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            backgroundColor: 'transparent',
            borderRadius: 4,
            color: 'black',
            font: {
              weight: 'bold'
            },
            formatter: (val) => {
              if (val === 0) {
                return ''
              }
              return val
            },
            padding: 6,
            display: 'auto',
            clamp: false,
            anchor: 'end',
            align: 'bottom'
          }
        }
      }
    }
  }
}
</script>
